import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import { useRouter } from "next/router"
import { columns } from "@constants/layout"
import Layout from "@components/Layout/hoc/withDatoData"
import Helmet from "@components/Helmet"
import LoginForm from "@components/LoginForm/hoc/withStore"

const Container = styled.div`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 64px 0 96px;

  ${up("laptop")} {
    padding: 138px 0 190px;
  }
`

const FormWrapper = styled.div`
  padding: 0 20px;

  ${up("laptop")} {
    width: 100%;
    min-width: 444px;
    max-width: calc(${columns(4)});
    padding: 0;
  }
`

const LoginPage: any = () => {
  const router = useRouter()

  const onSuccess = () => {
    router.push("/account")
  }

  return (
    <Layout>
      <Helmet title="Login" />
      <Container>
        <FormWrapper>
          <LoginForm onSuccess={onSuccess} />
        </FormWrapper>
      </Container>
    </Layout>
  )
}

export default LoginPage
